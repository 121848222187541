.container {
  position: relative;
  border-top: 1px solid #E8E8EC;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  .card {
    display: flex;
    gap: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #60646C;
    width: 100%;
  }

  .cardRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      font-weight: 600;
      font-size: 16px;
      color: #1C2024;
    }
    .link {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 400;
      font-size: 12px;
      color: #8B8D98;
      cursor: pointer;

      &:hover {
        color: #0C67E6;
      }
    }
  }
  .notice {
    display: flex;
    padding: 8px 12px;
    background-color: #F7F7F8;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #798190;
    gap: 4px;
    border-radius: 8px;
    margin-top: 24px;
    .warnIcon {
      width: 24px;
      height: 24px;
    }
  }
}
